import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { useCorrelation   } from 'hooks/useCorrelation'
import { TOption          } from 'models/ControlPanel'
import { zoneSettingStore } from 'store/ZoneSettingStore'

import { MaskedInput      } from 'grommet'
import { masks            } from '../helpers/masks'

export const InputOption =  observer (({
   setSetting,
   option,
   rowDisabled
} : {
   setSetting: (i, v) => void,
   option    : TOption,
   rowDisabled: boolean
}) => {

   const [value, setValue] = useState (
      String (zoneSettingStore.settings
      ?.find (set => set.name == option.id)?.value))

   useEffect (() => {
      setSetting (option.id, value)
   }, [value])

   const {
      style,
      className,
      disabled,
      ...correlation
   } = useCorrelation (
      option?.rel, 
      option?.func
      ? eval (option?.func) (value)
      : null
   )

   return (
      <MaskedInput
         { ...option }
         value     = { value }
         onChange  = { ({ target }) => setValue (target.value) }
         mask      = { masks[option.type]?.mask }
         type      = { masks[option.type]?.type }
         style     = { style }
         className = { className }
         disabled  = { disabled ?? rowDisabled }
         { ...correlation }
      />
   )
})