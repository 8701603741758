import { makeAutoObservable } from "mobx"

type TCorrelation = {
   rel          : string,
   unrender?    : boolean,
   style?       : object,
   className?   : string,
   label?       : string
   disabled?    : boolean,
   [key: string]: string | number | boolean | object
}

class CorrelationsStore {

   correlations: TCorrelation[] = []

   constructor () { makeAutoObservable (this) }

   setCorrelation (func: string) {

      const correlations: TCorrelation[] = eval ("(" + func + ")")

      if (correlations) {

         correlations.forEach ((correlation: TCorrelation) => {

            const idx = this.correlations
            .flatMap (cor => cor?.rel)
            .indexOf (correlation?.rel)

            if (idx === -1) this.correlations.push   (correlation)
            else            this.correlations.splice (idx, 1, correlation)
         })
      }

      this.correlations = this.correlations.slice ()
   }
}

export const correlationsStore = new CorrelationsStore()
