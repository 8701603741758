import React, { useContext } from 'react'
import { observer } from 'mobx-react'

import { TSetting       } from 'models/ControlPanel'
import { useCorrelation } from 'hooks/useCorrelation'

import {
   ResponsiveContext,
   TableCell
} from 'grommet'
import { InputOption  } from './components/InputOption'

export const RangeGroupRow = observer (({
   setSetting,
   row,
} : {
   setSetting: (i: string, v: string) => void,
   row       : TSetting
}) => {

   const size = useContext (ResponsiveContext)
   const {
      unrender,
      label,
      disabled
   } = useCorrelation (row?.rel)

   if (!unrender) return (
      <>
         <TableCell
            scope  = 'row'
            border = { {
               side: 'right',
               size: 'small'
            } }
         >
            { label ?? row?.label }
         </TableCell>

         <TableCell
            direction = { size == 'small' ? 'column' : 'row' }
            gap       = 'medium'
         >
            { row?.options.map (option => 
               <InputOption
                  key         = { option?.id }
                  setSetting  = { setSetting }
                  option      = { option }
                  rowDisabled = { disabled }
               />
            ) }
         </TableCell>
      </>
   )
})