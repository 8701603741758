import React, { useState, useEffect, useMemo } from 'react'
import { observer             } from 'mobx-react'

import { TSample              } from 'models/Sample'
import { TTag                 } from 'models/Tag'
import { TTagBlock            } from 'models/ControlPanel'
import { TControllerState     } from 'models/ControllerState'

import { controllerStateStore } from 'store/ControllerStateStore'
import { devicesStore         } from 'store/DevicesStore'

import { dayTitle             } from 'utils/dayTitle'
import { Loader               } from 'components/Common/Loader'
import { Modal                } from 'components/Library/Modal/Modal'
import { TagChart             } from './components/TagChart'
import { Button, Text         } from 'grommet'

import { getSamples } from 'api/sample'

export const TagCell = observer (( {
   block
} : {
   block: TTagBlock,
   children?: JSX.Element
}) => {

   const tag: TTag = useMemo (() => devicesStore.tags
   .find (tag => tag?.id == Number (block?.tag ?? block?.id) && tag.alias == block.alias),
   [block])

   const [modal , setModal ] = useState <boolean> ()
   const [sample, setSample] = useState <TSample> ()
   const [stat  , setStat  ] = useState <TControllerState> ()

   useEffect (() => {

      if (tag) {

         const getSample = async () => {
            const samples = await getSamples ([tag.id_tag])
            setSample (samples[0])
         }

         getSample ()
      }
   }, [tag])

   useEffect (() => {
   
      const st = controllerStateStore.state?.find (stat => stat.name === (block?.state ?? block?.id))

      if (st) {

         if (block?.divisor) st.value =  st.value / block.divisor
         if (block?.format ) st.value = +st.value.toFixed (block.format)
         else                st.value = +st.value.toFixed ()

         setStat (st)
      }
   }, [controllerStateStore.state])

   const difTime = Date.now () - new Date (sample?.dsample).getTime ()

   //console.log (devicesStore.tags
   //.find (tag => tag.id == Number (block?.tag ?? block.id) && tag.alias == block.alias))

   return (
      <>
         <Button
            primary
            onClick = { sample ? () => setModal (true) : undefined }
            style = { { padding: '8px' } }
         >

            { controllerStateStore.state?.length > 0
               ? (sample || stat)
                  ? (stat
                     ? block?.look
                        ? eval (block?.look) (stat?.value)
                        : `${stat?.value} ${block?.sign ?? tag?.sign}`
                     : (sample?.error_text
                        ? sample.error_text
                        : (
                           <>
                              { block?.look
                                 ? eval (block?.look) (sample?.sample)
                                 : sample?.sample } { block?.sign ?? tag?.sign }

                              { difTime > 600000 &&
                                 <Text
                                    size      = "xsmall"
                                    margin    = { { vertical: "xsmall" } }
                                 >
                                    { 'Показания устарели' }<br />{ 'Прошло: ' }
                                    {
                                       difTime > 86400000
                                       ? `${(difTime / 86400000).toFixed ()} ${dayTitle (Number ((difTime / 86400000).toFixed ()))}`
                                       : new Date (difTime).toLocaleTimeString ('ru-RU', { timeZone: 'UTC' })
                                    }
                                 </Text>
                              }
                           </>
                        )
                     )
                  ) : '-'
               : controllerStateStore.loading 
                  ? <Loader size = 'small' />
                  : '??'
            }
         </Button>

         { sample &&
            <Modal
               modal    = { modal }
               setModal = { setModal }
               heading  = { block?.label ?? tag?.name }
            >
               <TagChart 
                  tag   = { tag }
                  block = { block }
               />
            </Modal>
         }
      </>
   )
})