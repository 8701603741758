import { makeAutoObservable } from "mobx"
import { TControllerState   } from "models/ControllerState"

class ZoneSettingStore {

   loading = false
   settings: TControllerState[] = []

   constructor () { makeAutoObservable (this) }

   setSettings (settings: TControllerState[])
   {
      this.settings = settings
      this.setLoading (false)
   }

   setLoading (isLoading: boolean) 
   {
      this.loading = isLoading
   }
}

export const zoneSettingStore = new ZoneSettingStore ()
