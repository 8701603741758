import React, { useState, useEffect, useContext  } from 'react'

import { Box, Button, Heading, Layer, ResponsiveContext } from 'grommet'
import { Close, Contract, Expand     } from 'grommet-icons'
import { HeightType, WidthType       } from 'grommet/utils'

export const Modal = ({ 
   modal,
   setModal,
   heading,
   subheading,
   width,
   height,
   children
} : {
   modal      : boolean,
   setModal   : React.Dispatch <React.SetStateAction <boolean>>,
   heading    : string,
   subheading?: string,
   width?     : WidthType,
   height?    : HeightType,
   children   : JSX.Element,
}) => {

   const size = useContext (ResponsiveContext)
   const [bug   , setBug   ] = useState (true) //for bug of grommet
   const [expand, setExpand] = useState (false)

   useEffect (() => { setModal (false) }, [bug])

   if (modal) return (

      <Layer
         onEsc          = { () => setModal (false) }
         onClickOutside = { () => setModal (false) }
         full           = { expand }
         animation      = "fadeIn"
      >
         <Box
            width  = { expand ? 'auto' : width ?? 'large' }
            height = { height ?? { max: '90vh' } }
            fill   = { expand }
            pad    = { {
               top       : 'small',
               horizontal: 'medium',
               bottom    : 'medium'
            } }
            round
         >
            <Box
               justify   = 'between'
               direction = "row"
            >
               <Heading
                  margin = { { vertical: '10px' } }
                  level  = { 3 }
               >
                  { heading }
               </Heading>

               <Box
                  direction = 'row'
                  style     = { { display: 'block' } }
               >
                  { size != 'small' &&
                     <Button
                        icon    = { expand ? <Contract /> : <Expand /> }
                        onClick = { () => setExpand (!expand) } 
                     />
                  }
                  <Button
                     icon    = { <Close /> }
                     onClick = { () => setBug (!bug) } 
                  />
               </Box>
            </Box>

            <Box>
               <Heading
                  margin = { { top: 'none' } }
                  level  = { 3 }
               >
                  { subheading }
               </Heading>
            </Box>

            { children }

         </Box>
      </Layer>
   )
}
