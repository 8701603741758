import React from 'react'
import { observer } from 'mobx-react'

import { TControl             } from 'models/ControlPanel'
import { TControlComand       } from 'models/ControllerState'

import { useWaitCommand       } from 'components/Library/ControlFabric/hooks/useWaitCommand'
import { useCorrelation       } from 'hooks/useCorrelation'
import { controllerStateStore } from 'store/ControllerStateStore'

import { ControlFabric        } from "components/Library/ControlFabric/ControlFabric"
import { RadioButtonGroup     } from 'grommet'



export const ControlRadioGroup = observer (({
   alias,
   control,
   watchdog,
   sendCommand
} : {
   alias   : string,
   control : TControl,
   watchdog: boolean,
   sendCommand: TControlComand
}) => {

   const { controlCommand, wait } = useWaitCommand (sendCommand)

   const {
      unrender,
      style,
      className,
      label,
      disabled,
      ...correlation
   } = useCorrelation (
      control?.rel,
      control.options
      ?.find (option => option?.state == controllerStateStore?.state
      ?.find (state => state.name == control.id)?.value)?.func
   )

   if (!unrender) return (

      <ControlFabric wait = { wait }>
         <RadioButtonGroup
            name     = 'radio'
            options  =  { control.options.map (option => ({
               id   : String (option.command),
               label: 
                  <span
                     key   = { option.command }
                     style = { { textAlign: 'right' } }
                  >
                     { label ?? option.label }
                  </span>,
               value: control.look
                      ? eval (control.look)(option?.state)
                      : option?.state,
               disabled,
            })) }
            value    = { controllerStateStore.state
            ?.find (state => control.id == state.name)?.value }
            onChange = { event =>
               watchdog 
               ? controlCommand (alias, control.options
               .find (option => option.state == Number (event.target.value))?.command)
               : sendCommand    (alias, control.options
               .find (option => option.state == Number (event.target.value))?.command) }
            style   = { { gap: '12px', ...style } }
            className = { className }
            direction = 'row'
            wrap
            { ...correlation }
         />
      </ControlFabric>
   )
})