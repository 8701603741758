import { makeAutoObservable } from "mobx"
import { rootStore          } from "./RootStore"

import { 
   getControllerState,
   queryControllerState,
   setControllerCommand
} from "api/controller"

/* import { 
   getControllerState,
   queryControllerState,
   setControllerCommand
} from "api/dummyController" */

import {
   TControllerState,
   TStateRequestResult,
   TTarget
} from "models/ControllerState"

import { toast             } from "react-toastify"
import { errorHandler      } from "utils/errorHandler"
import { controlPanelStore } from "./ControlPanelStore"
import {
   POLL_TIMER,
   STATE_TIMER
} from "data/constants/globalConstants"

class ControllerStateStore {

   loading  = false
   idProject: number
   location : string
   state    : TControllerState[] = []
   globalContext = {}
   intervals: {
      [k: string]: ReturnType <typeof setTimeout>
   } = {}

   constructor () { makeAutoObservable (this) }

   getState (idProject: number)
   {
      this.setLoading (true)

      if (this.idProject != idProject) {
         this.idProject = idProject
         this.clearIntervals ()
         this.clearState ()
      }

      if (controlPanelStore.aliases.length > 0) {
         controlPanelStore.aliases.forEach (alias => {
            if (this.idProject && alias) this.queryStateCommand (this.idProject, alias)
         })
      } else this.setLoading (false)
   }

   setState (controllerState: TStateRequestResult) {

      switch (controllerState?.code) {
         case 0: {
            const stat = controllerState.data
            .find (ctrl => this.state
            .find (stat => stat?.name &&
                           stat.name  === ctrl.name &&
                           stat.value !== ctrl.value))

            if (this.state.length === 0 || stat) {
               this.state = controllerState.data

               if (Object.keys (this.intervals).length == 0) {

                  controlPanelStore.aliases.forEach (alias => {
         
                     if (alias)
                        this.intervals[alias] = setInterval (async () => {
         
                           if (rootStore.currentUserStore.currentUserData &&
                               this.idProject != null) this.queryStateCommand (this.idProject, alias)
                           else {
                              this.clearIntervals ()
                              this.clearState ()
                           }
                        }, (STATE_TIMER))
                  })
               }
            }
            break
         }

         case 7:
         case 102:
            controlPanelStore.aliases.forEach (alias => {
               if (this.idProject && alias) setTimeout (() => this.queryStateCommand (this.idProject, alias), POLL_TIMER)
            })
            break

         default:
            errorHandler (controllerState.message)
            this.clearIntervals ()
            this.clearState ()
            break
      }

      this.setLoading (false)
   }

   async getStateCommand (idProject: number, alias: string)
   {
      try {
         const state = await getControllerState (idProject, alias)
         if (state) this.setState (state)
      }
      catch (err) {
         if (!err.error_text) errorHandler (err)
         this.setLoading (false)
      }
   }

   async queryStateCommand (idProject: number, alias: string, idPacket?: number) 
   {
      try {
         const state = await queryControllerState (idProject, alias, idPacket)
         if (state) this.setState (state)
      }
      catch (err) {
         if (!err.error_text) errorHandler (err)
         this.setLoading (false)
      }
   }

   async setCommand (
      project: number,
      alias  : string,
      command: number,
      value? : number | TTarget,
   ) {
      const result = await setControllerCommand (
         project,
         alias,
         command, 
         value
      )

      if (result.Code != 0) toast.error (result.Message)
      return result
   }

   setLoading (isLoading: boolean)
   {
      this.loading = isLoading
   }

   setLocation (location: string)
   {
      this.location = location
   }

   clearIntervals ()
   {
      Object.values (this.intervals)
      .forEach (interval => clearInterval (interval))
      this.intervals = {}
   }

   clearState ()
   {
      this.state = []
   }

   setGlobalContext (value: object)
   {
      this.globalContext = value
   }
}

export const controllerStateStore = new ControllerStateStore ()