import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { TSetting         } from 'models/ControlPanel'
import { useCorrelation   } from 'hooks/useCorrelation'
import { zoneSettingStore } from 'store/ZoneSettingStore'

import {
   Box,
   Select,
   TableCell
} from 'grommet'
import { devicesStore } from 'store/DevicesStore'
import { toJS } from 'mobx'

export const SelectRow =  observer (({
   setSetting,
   row,
} : {
   setSetting: (i: string, v: string | number) => void,
   row       : TSetting
}) => {

   const [value, setValue] = useState (
      row?.options 
      ? zoneSettingStore.settings?.find (set => set.name == row.id)?.value
      : String (zoneSettingStore.settings?.find (set => set.name == row?.source)?.value)
   )

   useEffect (() => {
      setSetting (row?.options ? row.id : row?.source, value)
   }, [value])

   const {
      unrender,
      style,
      className,
      disabled,
      label,
      ...correlation
   } = useCorrelation (row?.rel, row.options
   ?.find (option => option?.state == value)?.func)

   //console.log (toJS (devicesStore.devices))

   if (!unrender) return (
      <>
         <TableCell
            scope  = 'row'
            border = { {
               side: 'right',
               size: 'small'
            } }
         >
            { label ?? row.label }
         </TableCell>
      
         <TableCell>
            { /* <Select
               { ...row }
               placeholder = 'Выберите'
               options     = { row?.options ?? 
                  eval (row?.exec) (devicesStore.devices
                  .flatMap (device => device.units)
                  .map (unit =>  {
                     if (String (zoneSettingStore.settings?.find (set => set.name == unit.alias))) return unit.name
                  })) }
               value       = { value }
               valueKey    = 'state'
               labelKey    = 'label'
               { ...(row?.options ? {} : { valueLabel: option => {
                  console.log (option)
                  return (
                     <Box>
                        { devicesStore.devices
                        .flatMap (device => device.units)
                        .find (unit => unit.alias == option)?.name }
                     </Box>
                  )
               } }) }
               onChange    = { ({ option }) => setValue (option.state) }
               disabled    = { disabled }
               style       = { style }
               className   = { className }
               { ...correlation }
            /> */ }
            <Select
               { ...row }
               placeholder = 'Выберите'
               options   = { row?.options ?? 
                  eval (row?.exec) (zoneSettingStore.settings?.find (set => set.name == row.id)?.value) }
               value     = { value }
               valueKey  = 'state'
               labelKey  = 'label'
               //valueLabel = { devicesStore.devices.find (device => device.units.some (unit => unit.alias == value))?.name }
               onChange  = { ({ option }) => setValue (option.state) }
               disabled  = { disabled }
               style     = { style }
               className = { className }
               { ...correlation }
            />
         </TableCell>
      </>
   )
})