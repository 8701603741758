import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { TControl             } from 'models/ControlPanel'
import { TControlComand       } from 'models/ControllerState'
import { controllerStateStore } from 'store/ControllerStateStore'
import { rootStore            } from 'store/RootStore'

import { ControlFabric        } from "components/Library/ControlFabric/ControlFabric"
import { useWaitCommand       } from "components/Library/ControlFabric/hooks/useWaitCommand"
import { useCorrelation       } from 'hooks/useCorrelation'
import {
   AddCircle,
   SubtractCircle
} from 'grommet-icons'

import {
   ResponsiveContext,
   Box,
   Button,
   RangeInput,
   Text
} from 'grommet'

export const ControlRangeInput = observer (({
   alias,
   control,
   watchdog,
   sendCommand
}: {
   alias    : string,
   control  : TControl,
   watchdog?: boolean,
   sendCommand: TControlComand
}) => {

   const size = useContext (ResponsiveContext)

   const [value, setValue] = useState <number> (controllerStateStore.state
   ?.find (state => state.name == control.id)?.value)
   const [confirm, setConfirm] = useState (false)

   const { controlCommand, wait } = useWaitCommand (sendCommand)

   const {
      unrender,
      style,
      className,
      label,
      disabled,
      ...correlation
   } = useCorrelation (
      control?.rel,
      control.options
      ?.find (option => option?.state == controllerStateStore?.state
      ?.find (state => state.name == control.id)?.value)?.func
   )

   useEffect (() => {
      if (!confirm)
         setValue (controllerStateStore.state?.find (state => state.name == control.id)?.value)
   }, [controllerStateStore?.state, confirm])

   if (!unrender) return (

      <Box direction = 'column'>

         { control.options.map (option => (

            <ControlFabric
               key  = { option.command }
               wait = { wait }
            >
               <Box>

                  { (typeof value !== 'undefined') &&

                     <>
                        <Box
                           justify = 'end'
                           fill    = 'horizontal'
                        >
                           <Text alignSelf = 'center'>
                              { (option?.text && value)
                                ? eval (option?.text) (controllerStateStore.state, rootStore.currentUserStore.currentUserData?.isadmin)
                                : `${label ?? option?.label}: ${value ?? 0} ${control?.sign ?? ''}`
                              }
                           </Text>
                        </Box>

                        <Box
                           direction = 'row'
                           margin    = { { top: 'medium' } }
                           fill      = 'horizontal'
                           gap       = 'medium'
                        >
                           <Button
                              secondary
                              icon     = { <SubtractCircle /> }
                              pad      = '0'
                              disabled = { disabled }
                              onClick  = { () => {
                                 setValue (prev => prev - 1)
                                 setConfirm (true)
                              } }
                           />
                           <RangeInput
                              value      = { control.look ? eval (control.look) (value) : value }
                              max        = { option.max }
                              min        = { option.min }
                              onChange   = { e => setValue (Number (e.target.value)) }
                              onTouchEnd = { () => setConfirm (true) }
                              onMouseUp  = { () => setConfirm (true) }
                              disabled   = { disabled }
                              style      = { style }
                              className  = { className }
                              { ...correlation }
                           />
                           <Button
                              secondary
                              icon     = { <AddCircle /> }
                              pad      = '0'
                              disabled = { disabled }
                              onClick  = { () => {
                                 setValue (prev => prev + 1)
                                 setConfirm (true)
                              } }
                           />
                        </Box>
                     </>
                  }

                  { confirm &&

                     <Box
                        direction = "row"
                        justify   = { size == 'small' ? 'between' : 'end' }
                        margin    = { { top: 'medium' } }
                        gap       = 'medium'
                     >
                        <Button
                           primary
                           label   = { 'Сохранить' }
                           onClick = { () => {
                              watchdog
                              ? controlCommand (alias, option.command, value)
                              : sendCommand    (alias, option.command, value)
                              setConfirm (false)
                           } }
                        />
                        <Button
                           label   = "Отменить"
                           onClick = { () => {
                              setValue (controllerStateStore.state
                              ?.find (state => state.name == control.id)?.value)
                              setConfirm (false)
                           } }
                        />
                     </Box>
                  }
               </Box>
            </ControlFabric>
         )) }
      </Box>
   )
})
