import React, { useMemo } from 'react'
import { observer             } from 'mobx-react'

import { TControl             } from 'models/ControlPanel'
import { TControlComand       } from 'models/ControllerState'

import { controllerStateStore } from 'store/ControllerStateStore'
import { useWaitCommand       } from 'components/Library/ControlFabric/hooks/useWaitCommand'
import { useCorrelation       } from 'hooks/useCorrelation'

import { Box, Button          } from 'grommet'
import { ControlFabric        } from "components/Library/ControlFabric/ControlFabric"

export const ControlButton = observer (({
   alias,
   control,
   watchdog,
   sendCommand
}: {
   alias   : string,
   control : TControl,
   watchdog: boolean,
   sendCommand: TControlComand
}) => {

   const stat = useMemo (() => controllerStateStore?.state
   ?.find (state => state.name == control.id),
   [controllerStateStore?.state, control])

   const option = control.options
   ?.find (option => option?.state == stat?.value)

   const { controlCommand, wait } = useWaitCommand (sendCommand)

   const {
      unrender,
      style,
      className,
      label,
      disabled,
      ...correlation
   } = useCorrelation (control?.rel, option?.func)

   if (!unrender) return (

      <ControlFabric
         key  = { control.id }
         wait = { wait }
      >
         <Box
            direction = 'row'
            flex      = 'grow'
            wrap
         >
            <Button
               size      = "large"
               primary   = { option?.state != undefined ? !!option?.state : true }
               label     = { label ?? option?.state ? (option?.labelOn || option?.label) : (option?.labelOff || option?.label) } 
               onClick   = { () => watchdog 
                                   ? controlCommand (alias, option?.command)
                                   : sendCommand    (alias, option?.command) }
               fill      = 'horizontal'
               disabled  = { disabled }
               className = { className }
               style     = { {
                  ...style,
                  borderRadius : 0,
                  pointerEvents: option?.command ? 'auto' : 'none'
               } }
               { ...correlation }
            />
         </Box>
      </ControlFabric>
   )
})
