import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { observer  } from "mobx-react"

import { controllerStateStore } from "store/ControllerStateStore"
import { TCustomBlock         } from "models/ControlPanel"

import { Box, Button, CardBody, CardFooter, RangeInput, Text } from "grommet"

import { ControlFabric        } from "components/Library/ControlFabric/ControlFabric"
import { useWaitCommand       } from "components/Library/ControlFabric/hooks/useWaitCommand"
//import { DecimalInput } from "components/Library/components/DecimalInput"
import { AddCircle, SubtractCircle } from "grommet-icons"

export const TargetEdit = observer (({
   block,
   targetValue,
   setTargetValue,
   setEdit
}: {
   block         : TCustomBlock,
   targetValue   : number,
   setTargetValue: React.Dispatch<React.SetStateAction<number>>
   setEdit       : (boolean) => void
}) => {

   const { idProject } = useParams ()
   const [valueView, setValueView] = useState <number> (block?.look ? eval (block?.look) (targetValue) : targetValue)

   useEffect (() => {
      setTargetValue (block?.muta ? eval (block?.muta) (valueView) : valueView)
   }, [valueView])

   const option = useMemo (() => 
      block.controls.flatMap (control => control.options)[0],
   [block.controls])

   const sendCommand = useCallback (async (
      alias: string,
      command: number,
      value: number
   ) => {
      setEdit (false)
      return controllerStateStore.setCommand (
         Number (idProject),
         block.alias,
         command,
         value
      )
   }, [block.alias, idProject])

   const { controlCommand, wait } = useWaitCommand (sendCommand)

   return (
      <>
         <CardBody margin = { { vertical: "xsmall" } } >
            <Text
               textAlign = 'center'
               weight    = 'bold'
               size      = "2xl"
               margin    = { { vertical: "xsmall" } }
            >
               { valueView } { block.sign && ' ' + block?.sign }
            </Text>
            <Box
               direction = 'row'
               margin    = { { top: 'medium' } }
               fill      = 'horizontal'
               gap       = 'medium'
            >
               <Button
                  secondary
                  icon     = { <SubtractCircle /> }
                  pad      = '0'
                  //disabled = { disabled }
                  onClick  = { () => {
                     setValueView (prev => prev - 1)
                  } }
               />
               <RangeInput
                  value      = { valueView }//{ control.look ? eval (control.look) (value) : value }
                  max        = { option.max }
                  min        = { option.min }
                  onChange   = { e => setValueView (Number (e.target.value)) }
                  //onTouchEnd = { () => setConfirm (true) }
                  //onMouseUp  = { () => setConfirm (true) }
                  //disabled   = { disabled }
                  //style      = { style }
                  //className  = { className }
                  { /* ...correlation */ ...[] }
               />
               <Button
                  secondary
                  icon     = { <AddCircle /> }
                  pad      = '0'
                  //disabled = { disabled }
                  onClick  = { () => {
                     setValueView (prev => prev + 1)
                  } }
               />
            </Box>
            { /* <DecimalInput
               title    = 'Целевое значение'
               name     = { block.id }
               type     = 'temperature'
               value    = { valueView }
               setValue = { setValueView }
               option   = { option }
            /> */ }
         </CardBody>

         <CardFooter justify = 'between' >
            <Button
               secondary
               label   = 'Отмена'
               margin  = { { top: 'small' } }
               onClick = { () => setEdit (false) }
            />

            <ControlFabric wait = { wait } >
               <Button
                  primary
                  label     = 'Применить'
                  margin    = { { top: 'small' } }
                  onClick   = { () => controlCommand (block.alias, option.command, targetValue) }
               />
            </ControlFabric>

         </CardFooter>
      </>
   )
})