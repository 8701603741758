import React from "react"

import { TTagTable } from "models/ControlPanel"
import { DataTable } from "grommet"
import { TagCell   } from "./components/TagCell"

import { FaDroplet } from "react-icons/fa6"
import { FaThermometerHalf } from "react-icons/fa";

const icons = {
   '°C': <FaThermometerHalf
      style = { {
         alignSelf: 'center'
      } }
      size = { 24 }
   />,
   '%': <FaDroplet
      style = { {
         alignSelf: 'center'
      } }
      size = { 24 }
   />,
   PPM: <>{ 'CO2' }</>
}

export const TagTable = ( { block } : { block: TTagTable }) => {

   const columns = block.columns.map (column => ({
      align   : "center",
      property: column.celltype,
      header  : icons[column.sign],
      render  : row => (
         <TagCell
            block = { row[column.celltype] }
         />
      )
   }))

   return (
      <DataTable
         pad       = 'xxsmall'
         alignSelf = 'center'
         data      = { block.rows }
         columns   = { [
            {
               property: 'label',
               header  : 'Комната',
               primary : true
            },
            //@ts-ignore
            ...columns
         ] }
      />
   )
}