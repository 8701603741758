import React, { useEffect, useState } from "react"
import { observer  } from "mobx-react"
import { useParams } from "react-router-dom"

import { projectStore } from "store/ProjectsStore"
import { TProjectItem } from "models/Project"

import { Box, Heading, Text } from "grommet"
import { Loader             } from "components/Common/Loader"

export const PageTitle = observer (() => {

   const { idProject }         = useParams ()
   const [project, setProject] = useState <TProjectItem> ()

   useEffect(() => {

      if (projectStore.projects?.length > 0)
         setProject (projectStore.projects.find ((project) => project.id_project == Number (idProject)))
   }, [projectStore.projects])

   if (projectStore.loading) return <Loader size = "small"/>
   else {
      if (!project) return null

      return (

         <Box 
            direction = "column" 
            align     = "start" 
            alignSelf = "center"
            style     = { {
               textOverflow: "ellipsis",
               whiteSpace  : "nowrap",
               overflow    : "hidden",
               maxWidth    : 360
            } }
         >
            <Heading
               margin    = "none"
               level     = { 3 } 
               textAlign = 'start'
            >
               { project ? project?.name : 'Контрольная панель' }
            </Heading>

            { project.remark &&

               <Text size = "small">
                  <b>Описание: </b>{ project?.remark }<br />
               </Text>
            }
         </Box>
      )
   }
})