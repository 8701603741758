import React from "react"

import { Box, Button, Text } from "grommet"
import { SideBarLink } from "./styles"

export const MainSideBarButton = ({
   callback,
   icon,
   link,
   name,
} : {
   callback?: () => void,
   icon     : JSX.Element,
   link?    : string,
   name     : string,
}) => {

   return (
      <Button
         hoverIndicator
         onClick = { () => callback?.() }
      >
         <SideBarLink to = { link || "" } >
            <Box
               pad       = { { horizontal: "small", vertical: "small" } }
               direction = "row"
               align     = "center"
               gap       = "small"
            >
               { icon }
               <Text>
                  { name }
               </Text>
            </Box>
         </SideBarLink>
      </Button>
   );
}
