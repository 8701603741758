import React from 'react'
import { observer } from 'mobx-react'

import { TControlBlock, TCustomBlock, TTagBlock } from 'models/ControlPanel'
import { TGroup         } from 'models/ControlPanel'

import { useCorrelation } from 'hooks/useCorrelation'
import { useBlocks      } from 'hooks/useBlocks'

import { Box, Heading   } from 'grommet'
import { Setting        } from 'components/Setting/Setting'

export const Group = observer (({ block } : { block: TGroup }) => {

   const {
      unrender,
      style,
      className,
      label,
      ...correlation
   } = useCorrelation (block?.rel)

   if (!unrender) 
      if (block?.label != undefined) {

         return (
            <Box
               cssGap
               round
               gap        = 'medium'
               direction  = 'column'
               pad        = "medium"
               flex       = 'grow'
               border     = { { color: 'brand', size: 'xsmall' } }
               //@ts-ignore
               background = { block?.style?.background }
               style      = { { ...(block?.style ?? ''), ...(style ?? '') } }
               className  = { `${className ?? ''} ${block?.class ?? ''}` }
               
               { ...correlation }
            >
               <Box
                  direction = 'row'
                  justify   = 'between'
                  align     = 'center'
                  wrap
               >
                  { (label || block?.label) &&
                     <Heading
                        level  = { 3 }
                        margin = { { vertical: 'none' } }
                     >
                        { label ?? block?.label }
                     </Heading>
                  }

                  { block?.setting && block.setting?.settings?.length > 0 &&
                     <Setting setting = { block?.setting } />
                  }
               </Box>

               <Box
                  cssGap
                  round
                  gap        = 'medium'
                  direction = 'row'
                  flex      = { { grow: 1, shrink: 1 } }
                  style      = { { ...(block?.style ?? ''), ...(style ?? '') } }
                  className  = { `${className ?? ''} ${block?.class ?? ''}` }
                  wrap
               >
                  { block.group?.map ((
                     block:
                     TControlBlock |
                     TCustomBlock  |
                     TTagBlock     |
                     TGroup
                  ) => useBlocks (block)) }
               </Box>
            </Box>
         )
      }
      else {

         return (
            <Box
               round
               wrap
               cssGap
               gap        = 'medium'
               direction  = 'row'
               flex       = { { grow: 1, shrink: 1 } }
               fill       = 'horizontal'
               //@ts-ignore
               background = { block?.style?.background }
               style      = { { ...(block?.style ?? ''), ...(style ?? '') } }
               className  = { `${className ?? ''} ${block?.class ?? ''}` }
            >
               { block.group?.map ((
                  block:
                  TControlBlock |
                  TCustomBlock  |
                  TTagBlock     |
                  TGroup
               ) => useBlocks (block)) }
            </Box>
         )
      }
})