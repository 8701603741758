import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { useCorrelation   } from 'hooks/useCorrelation'
import { TSetting         } from 'models/ControlPanel'
import { zoneSettingStore } from 'store/ZoneSettingStore'

import {
   RadioButtonGroup,
   ResponsiveContext,
   TableCell
} from 'grommet'

export const RadioGroupRow =  observer (({
   setSetting,
   row,
} : {
   setSetting: (i: string, v: string) => void,
   row       : TSetting
}) => {

   const size = useContext (ResponsiveContext)
   const [value, setValue] = useState (
      zoneSettingStore.settings
      ?.find (set => set.name == row.id)?.value)

   useEffect (() => {
      setSetting (row.id, String (value))
   }, [value])

   const {
      unrender,
      style,
      className,
      disabled,
      label,
      ...correlation
   } = useCorrelation (row?.rel, row.options
   ?.find (option => option?.state == value)?.func)

   if (!unrender) return (
      <>
         <TableCell
            scope  = 'row'
            border = { {
               side: 'right',
               size: 'small'
            } }
            verticalAlign = 'top'
         >
            { label ?? row.label }
         </TableCell>

         <TableCell>
            <RadioButtonGroup
               { ...row }
               direction = { size == 'small' ? 'column' : 'row' }
               name      = 'radio'
               options   =  { row.options.map (option => ({
                  id      : option.id,
                  label   : option?.label,
                  value   : option?.state,
                  disabled: disabled,
               })) }
               value     = { value }
               onChange  = { ({ target }) => setValue (Number (target.value)) }
               style     = { style }
               className = { className }
               wrap
               { ...correlation }
            />
         </TableCell>
      </>
   )
})