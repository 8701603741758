import { api } from "api/client"
import { TStateRequestResult, TTarget } from "models/ControllerState"

export const getControllerState = async (
   id_project: number,
   alias     : string
): Promise<TStateRequestResult> => {
   return await api.send ('GET', `/controller/?alias=${alias}&id_project=${id_project}`)
}

export const queryControllerState = async (
   id_project: number,
   alias     : string,
   id_packet? : number
): Promise<TStateRequestResult> => {
   return await api.send ('GET', `/controller/?alias=${alias}&id_project=${id_project}${id_packet ? `&id_packet=${id_packet}` : ''}&hash`)
}

export const setControllerCommand = async (
   id_project: number,
   alias  : string,
   command: number,
   value? : number | TTarget,
): Promise <{
   Code   : number,
   Message: string
   Data   : { PacketId: number }
}> => {
   return await api.send ('POST', '/controller/', {
      id_project,
      alias,
      command,
      value
   })
}

export const setControllerSetting = async (
   id_project: number,
   alias     : string,
   command   : number,
   setting?  : {id: string, value: string}[]
): Promise<{
   Code   : number,
   Message: string
}> => {
   return await api.send ('POST', '/controller/', {
      id_project,
      alias,
      command: command,
      setting
   })
}