import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { TSetting         } from 'models/ControlPanel'
import { useCorrelation   } from 'hooks/useCorrelation'
import { zoneSettingStore } from 'store/ZoneSettingStore'

import { AddCircle, SubtractCircle } from 'grommet-icons'
import {
   Box,
   Button,
   Card,
   CardBody,
   CardHeader,
   Heading,
   RangeInput,
   TableCell
} from 'grommet'

export const RangeInputRow =  observer (({
   setSetting,
   row,
} : {
   setSetting: (i: string, v: string) => void,
   row       : TSetting
}) => {

   const [value, setValue] = useState (
      zoneSettingStore.settings
      ?.find (set => set.name == row.id)?.value)

   useEffect (() => {
      setSetting (row.id, String (value))
   }, [value])

   const {
      unrender,
      style,
      className,
      disabled,
      label,
      ...correlation
   } = useCorrelation (row?.rel, row.options
   ?.find (option => option?.state == value)?.func)

   if (!unrender) return (
      <TableCell
         pad = { { vertical: 'small' } }
         colSpan = { 2 }
      >
         <Card
            border     = { row?.label ? { color: 'brand', size: 'xsmall' } : false }
            pad        = { { horizontal: "medium" } }
            flex       = 'grow'
            width      = { 'medium' }
            style      = { style }
            className  = { className }
            { ...correlation }
            fill
         >
            <CardHeader
               direction = 'row'
               pad = 'none'
            >
               <Heading
                  level = { 4 }
                  style = { {
                     whiteSpace: 'nowrap',
                     padding   : 'none'
                  } }
               >
                  { `${label ?? row?.label} (${value} ${row.sign})` }
               </Heading>
            </CardHeader>

            <CardBody>
               <Box
                  margin    = { { bottom: 'medium' } }
                  fill      = 'horizontal'
               >
                  <Box
                     direction = 'row'
                     gap       = 'medium'
                  >
                     <Button
                        secondary
                        icon    = { <SubtractCircle /> }
                        pad     = '0'
                        onClick = { () => setValue (prev => prev - 1) }
                        disabled  = { disabled }
                     />
                     <RangeInput
                        { ...row }
                        value     = { value }
                        max       = { row.max }
                        min       = { row.min }
                        onChange  = { ({ target }) => setValue (Number (target.value)) }
                        disabled  = { disabled }
                        style     = { style }
                        className = { className }
                        { ...correlation }
                     />
                     <Button
                        secondary
                        icon    = { <AddCircle /> }
                        pad     = '0'
                        onClick = { () => setValue (prev => prev + 1) }
                        disabled  = { disabled }
                     />
                  </Box>
               </Box>
            </CardBody>
         </Card>
      </TableCell>
   )
})