import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'

import { TCPSetting     } from 'models/ControlPanel'
import { useCorrelation } from 'hooks/useCorrelation'
import { SettingsOption } from 'grommet-icons'
import { Modal          } from 'components/Library/Modal/Modal'
import { SettingTable   } from './components/SettingTable'
import {
   Button,
   ResponsiveContext,
} from 'grommet'

export const Setting = observer (({ setting } : { setting: TCPSetting }) => {

   const size = useContext (ResponsiveContext)
   const [modal, setModal] = useState (true)

   const {
      unrender,
      style,
      className,
      label,
      disabled,
      ...correlation
   } = useCorrelation (setting?.rel)

   if (!unrender) return (
      <>
         <Button
            { ...(size == 'medium' || size == 'large' ? { primary: true } : { secondary: true }) }
            { ...(size == 'medium' || size == 'large' ? { label: label ?? 'Настройка' } : {} ) }
            icon      = { <SettingsOption /> }
            onClick   = { () => setModal (true) }
            pad       = 'xsmall'
            disabled  = { disabled }
            style     = { style }
            className = { className }
            { ...correlation }
         />

         <Modal
            modal    = { modal }
            setModal = { setModal }
            heading  = 'Настройки'
         >
            <SettingTable
               setting  = { setting }
               setModal = { setModal }
            />
         </Modal>
      </>
   )
})