import React from 'react'
import { observer } from 'mobx-react'

import { TGroup            } from 'models/ControlPanel'
import { controlPanelStore } from 'store/ControlPanelStore'
import { Group             } from './components/Group'

export const Groups = observer (() => {

   if (controlPanelStore.groups?.length > 0) {
      return controlPanelStore.groups.map ((group: TGroup) => 
         <Group
            key   = { group.id }
            block = { group }
         />
      )
   } else return ('Группы не обнаружены')
})