import { useEffect } from "react"
import { useParams } from "react-router-dom"

//import { getControllerSetting  } from "api/dummyController"
import { queryControllerState    } from "api/controller"
import { errorHandler            } from "utils/errorHandler"

import { TControlComand, TTarget } from "models/ControllerState"
import { controllerStateStore    } from "store/ControllerStateStore"
import { zoneSettingStore        } from "store/ZoneSettingStore"

import {
   CONTROL_TIMER,
   POLL_TIMER
} from "data/constants/globalConstants"

export const useWaitCommand = (sendCommand: TControlComand) => {

   const { idProject } = useParams ()
   useEffect (() => {
      zoneSettingStore.setSettings ([])
   }, [])

   const getSettingCommand = async (idProject: number, alias: string, idPacket: number) => {
      try {
         const state = await queryControllerState (idProject, alias, idPacket)
         if (state && state?.code == 0) return state?.data
      }
      catch (err) {
         if (!err.error_text) errorHandler (err)
      }
   }

   const controlCommand = async (
      alias?  : string,
      command?: number,
      value?  : number | string | TTarget
   ) => {

      let state = []
      controllerStateStore.setGlobalContext ({ disabled: true })

      sendCommand (alias, command, value)
      .then (result => {

         let timer = true
         const getState = async () => {

            state = await getSettingCommand (Number (idProject), alias, result.Data.PacketId)

            if (state && state
            ?.find (stat => stat.name == 'RequestId')
            ?.value == result.Data.PacketId) {

               controllerStateStore.setGlobalContext ({ disabled: false })
               zoneSettingStore.setSettings (state)
            }
            else if (timer === false) {

               controllerStateStore.setGlobalContext ({ disabled: false })
            } else setTimeout (() => getState (), POLL_TIMER)
         }

         setTimeout (() => timer = false, CONTROL_TIMER)
         getState ()
      })
   }

   return { controlCommand }
}