import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { TSetting         } from 'models/ControlPanel'
import { zoneSettingStore } from 'store/ZoneSettingStore'
import { useCorrelation   } from 'hooks/useCorrelation'

import { masks } from './helpers/masks'
import {
   MaskedInput,
   TableCell
} from 'grommet'

export const InputRow =  observer (({
   setSetting,
   row,
} : {
   setSetting: (i: string, v: string) => void,
   row       : TSetting
}) => {

   const [value, setValue] = useState (
      String (zoneSettingStore.settings
      ?.find (set => set.name == row.id)?.value))

   useEffect (() => {
      setSetting (row.id, value)
   }, [value])

   const {
      unrender,
      style,
      className,
      disabled,
      label,
      ...correlation
   } = useCorrelation (row?.rel, row.options
   ?.find (option => option?.state == value)?.func)

   if (!unrender) return (
      <>
         <TableCell
            scope  = 'row'
            border = { {
               side: 'right',
               size: 'small'
            } }
            verticalAlign = 'top'
         >
            { label ?? row.label }
         </TableCell>

         <TableCell>
            <MaskedInput
               { ...row }
               value     = { value }
               onChange  = { ({ target }) => setValue (target.value) }
               mask      = { masks[row.type] }
               disabled  = { disabled }
               style     = { style }
               className = { className }
               type      = { masks[row.type]?.type }
               { ...correlation }
            />
         </TableCell>
      </>
   )
})