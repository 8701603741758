import React from "react"
import { rootStore } from "store/RootStore"

import { IS_LOCAL        } from "data/constants/globalConstants"

import { Box, Heading    } from "grommet"
import { Loader          } from "../Common/Loader"
import { UserSettingCard } from "./components/UserSettingCard"
import { WifiSettingCard } from "./components/WifiSettingCard"

export const SettingComponent = () => {

   const user = rootStore.currentUserStore.currentUserData

   return (
      <Box
         pad    = "medium"
         margin = "medium"
      >
         <Heading
            margin = { {
               horizontal: 'small',
               top       : 'none'
            } }
         >
           Настройки
         </Heading>

         { user
            ? (
               <Box
                  wrap
                  gap  = 'medium'
                  flex = { { grow: 0, shrink: 1 } }
                  direction = "row"
               >
                  { user &&
                     <UserSettingCard
                        title = 'Пользователь'
                        data  = { user }
                     />
                  }

                  { IS_LOCAL &&
                     <WifiSettingCard
                        title = 'WiFi'
                        data  = { user }
                     />
                  }
               </Box>
            )
            : <Loader />
         }
      </Box>
   )
};