import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { TSetting         } from 'models/ControlPanel'
import { useCorrelation   } from 'hooks/useCorrelation'
import { zoneSettingStore } from 'store/ZoneSettingStore'

import {
   TableCell,
   TextArea
} from 'grommet'

export const TextAreaRow =  observer (({
   setSetting,
   row,
} : {
   setSetting: (i: string, v: string) => void,
   row       : TSetting
}) => {

   const [value, setValue] = useState (
      String (zoneSettingStore.settings
      ?.find (set => set.name == row.id)?.value))

   useEffect (() => {
      setSetting (row.id, value)
   }, [value])

   const {
      unrender,
      disabled,
      label,
      ...correlation
   } = useCorrelation (row?.rel, row.options
   ?.find (option => option?.state == value)?.func)

   if (!unrender) return (
      <>
         <TableCell
            scope  = 'row'
            border = { {
               side: 'right',
               size: 'small'
            } }
            verticalAlign = 'top'
         >
            { label ?? row.label }
         </TableCell>
      
         <TableCell>
            <TextArea
               { ...row }
               placeholder = "Настройки порта"
               value    = { String (value) }
               onChange = { ({ target }) => setValue (target.value) }
               disabled = { disabled }
               rows     = { 14 }
               resize   = { false }
               { ...correlation }
            />
         </TableCell>
      </>
   )
})