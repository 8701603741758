import React, { useCallback, useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { Box, Button, Card, CardBody, CardHeader, CheckBox, Heading, ResponsiveContext } from 'grommet'

import { controllerStateStore } from 'store/ControllerStateStore'
import { TCustomBlock } from 'models/ControlPanel'

import { ControlFabric } from "components/Library/ControlFabric/ControlFabric"
import { controlPanelStore } from 'store/ControlPanelStore'
import { useParams } from 'react-router-dom'

export const ToogleButtonBlock = observer (({ block }: { block: TCustomBlock }) => {

   const size = useContext (ResponsiveContext)
   const { idProject } = useParams ()

   const [isEnable, setIsEnable] = useState <boolean>(false)
   const [value   , setValue   ] = useState <number | string>(1)

   useEffect (() => {

      const stat = controllerStateStore.state
      ?.find (state => state.name == block.id)?.value

      setIsEnable ((stat & 0x80000000) ? true : false)
      setValue     (stat & 0x7FFFFFFF)
   }, [controllerStateStore.state])

   const controlCommand = useCallback (async (value, isEnable) => {

      if (isEnable) value |= 0x80000000
      const command = block.command

      controllerStateStore.setCommand (Number (idProject), block.alias, command, value)

   }, [idProject, controlPanelStore.controlBlocks, controlPanelStore.targetBlocks, value, isEnable])

   return (

      <Card
         border = { { color: 'brand', size: 'xsmall' } }
         pad    = { { horizontal: "medium" } }
         flex   = 'grow'
         width  = { size == 'large' ? 'large' : 'medium' }
      >
         <CardHeader>
            <Heading 
               level = { 3 }
               style = { { whiteSpace: 'nowrap' } }
            >
               { block?.label }
            </Heading>

            { (block && controllerStateStore.state?.length > 0) &&

               block.controls
               .filter  (control => control.type == 'Toogle')
               .flatMap (control => control.options
               .filter  (option  => !!option.state == isEnable )
               .map     ((option, i)  => 

                  <ControlFabric
                     key  = { i }
                     wait = { false }
                  >
                     <CheckBox
                        label    = { size != 'small' ? <span style = { { textAlign: 'right' } }>{ option?.label }</span> : '' }
                        checked  = { control.look ? eval (control.look) (isEnable) : isEnable }
                        onChange = { () => {
                           controlCommand (value, !option.state)
                           setIsEnable (!option.state)
                        } }
                        toggle
                        reverse
                     />
                  </ControlFabric>
               ))
            }

         </CardHeader>

         <CardBody>

            { (block.controls.some (control => control.type == 'Button') && controllerStateStore.state?.length > 0) &&

               <Box
                  pad       = { { vertical: "medium" } }
                  direction = "column"
                  fill
               >
                  <ControlFabric wait = { false }>
                     <Box
                        direction = 'row'
                        wrap
                     >
                        { block.controls
                        .filter (control => control.type == 'Button')
                        .map   ((control, i) => (

                           <Box
                              key  = { i }
                              flex = 'grow'
                           >

                              { control.options.map (option => (
                                 <Button
                                    key     = { option.state }
                                    size    = "large"
                                    primary = { value == option.state ? true : false }
                                    label   = { controllerStateStore.state
                                    ?.find (state => state.name == block.id)?.value
                                            ? (option?.labelOn  || option?.label)
                                            : (option?.labelOff || option?.label) } 
                                    style   = { { borderRadius: 0 } }
                                    fill    = 'horizontal'
                                    onClick = { () => {
                                       controlCommand (option.state, isEnable)
                                       setValue (option.state)
                                    } }
                                 />
                              )) }

                           </Box>
            
                        )) }
                     </Box>
                  </ControlFabric>
               </Box>
            }

         </CardBody>
      </Card>
   )
})