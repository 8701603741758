import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { observer  } from 'mobx-react'
import { useParams } from 'react-router-dom'

import { TCustomBlock         } from 'models/ControlPanel'
import { TTarget              } from 'models/ControllerState'

import { controllerStateStore } from 'store/ControllerStateStore'

import {
   Card,
   CardHeader,
   Heading,
} from 'grommet'
import { TargetScheduleValue  } from './components/TargetScheduleValue'
import { TargetScheduleEnable } from './components/TargetScheduleEnable'
import { useWaitCommand       } from 'components/Library/ControlFabric/hooks/useWaitCommand'

export const TargetSchedule = observer (({ block }: { block: TCustomBlock }) => {

   const { idProject } = useParams ()
   const [edit, setEdit] = useState (false)

   const getTargetValue = useCallback (() => {

      const stat = controllerStateStore.state.find (option => option.name == block.id)?.value

      if (stat)
         return ({
            isenable: (stat & 0x80000000) ? true : false,
            time1   : (stat >> 24) & 0x7F,
            value1  : (stat >> 16) & 0xFF,
            time2   : (stat >> 8 ) & 0xFF,
            value2  : stat & 0xFF
         })
   }, [controllerStateStore.state, edit])

   const [targetValue, setTargetValue] = useState <TTarget> (() => getTargetValue ())

   useEffect (() => {
      if (!edit) setTargetValue (() => getTargetValue ())
   }, [controllerStateStore.state])

   const command = useMemo (() =>
      block.controls
      .flatMap (({ options }) => options)
      .find    (option => /Значение/.test (option.id))?.command
   , [])

   const { controlCommand, wait } = useWaitCommand (
      async (
         alias  : string,
         command: number,
         value  : number
      ) => {
         setEdit (false)
         return controllerStateStore.setCommand (Number (idProject), alias, command, value)
      },
   )

   const sendCommand = (target: TTarget = targetValue) => {

      if (target) {

         let first = Number (target.time1)
         if (target.isenable) first |= 0x80;

         const value = first << 24 |
                       Number (target.value1) << 16 |
                       Number (target.time2 ) << 8  |
                       Number (target.value2)

         controlCommand (block.alias, command, value)
      }
   }

   return (

      <Card
         pad       = "medium"
         border    = { { color: 'brand', size: 'xsmall' } }
         flex      = 'grow'
         style     = { block.style }
         className = { block.class }
         //@ts-ignore
         background = { block?.style?.background }
      >
         <CardHeader justify = "center">
            <Heading
               level  = { 3 }
               margin = { { top: 'xxsmall', bottom: 'none' } }
            >
               { block.label }
            </Heading>
         </CardHeader>

         { !edit &&
            <TargetScheduleEnable
               block          = { block }
               targetValue    = { targetValue }
               setEdit        = { setEdit }
               sendCommand    = { sendCommand }
               wait           = { wait }
            />
         }

         { edit &&
            <TargetScheduleValue
               targetValue    = { targetValue }
               setEdit        = { setEdit }
               setTargetValue = { setTargetValue }
               sendCommand    = { sendCommand }
            />
         }
      </Card>
   )
})