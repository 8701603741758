import React, { useCallback, useMemo, useState } from "react"
import { observer  } from "mobx-react"
import { useParams } from "react-router-dom"

import { controllerStateStore } from "store/ControllerStateStore"
import { TCustomBlock         } from "models/ControlPanel"
import { useWaitCommand       } from "components/Library/ControlFabric/hooks/useWaitCommand"

import { Button, CardBody, CardFooter } from "grommet"
import { ControlFabric        } from "components/Library/ControlFabric/ControlFabric"
import { DecimalInput         } from "components/Library/components/DecimalInput"

export const TargetValue = observer (({
   block,
   targetValue,
   setEdit
}: {
   block      : TCustomBlock,
   targetValue: number,
   setEdit    : (a: boolean) => void
}) => {

   const { idProject } = useParams ()

   const valueCommand = useMemo (() => block.controls
   .flatMap (control => control.options).find (option => /Значение/.test (option.label)).command,
   [block.controls])

   const [value, setValue] = useState <number> (targetValue)

   const sendCommand = useCallback ( async (
      alias  : string,
      command: number,
      value  : number
   ) => (controllerStateStore.setCommand (Number (idProject), alias, command, value)),
   [block.alias, idProject])

   const { controlCommand, wait } = useWaitCommand (sendCommand)

   return (
      <>
         <CardBody margin = { { vertical: "xsmall" } }>
            <DecimalInput
               title    = 'Целевое значение'
               name     = { block.id }
               type     = 'temperature'
               value    = { value }
               setValue = { setValue }
            />
         </CardBody>

         <CardFooter justify = 'between'>
            <Button
               secondary
               label   = 'Отмена'
               margin  = { { top: 'small' } }
               onClick = { () => setEdit (false) }
            />

            <ControlFabric wait = { wait } >
               <Button
                  primary
                  label   = 'Применить'
                  margin  = { { top: 'small' } }
                  onClick = { () => controlCommand (block.alias, valueCommand, value) }
               />
            </ControlFabric>

         </CardFooter>
      </>
   )
})