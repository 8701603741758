import React, { useCallback, useEffect, useState } from 'react'
import { observer   } from 'mobx-react'
import { useParams  } from 'react-router-dom'

import { 
   setControllerCommand,
   setControllerSetting
} from 'api/controller'

import { TCPSetting       } from 'models/ControlPanel'
import { zoneSettingStore } from 'store/ZoneSettingStore'
import { useWaitCommand   } from 'components/Setting/components/hooks/useWaitCommand'
import {
   TControlComand,
   TTarget
} from 'models/ControllerState'

import { SettingRow } from 'components/Setting/components/SettingRow'
import { Loader     } from 'components/Common/Loader'
import {
   Box,
   Button,
   Table,
   TableBody,
} from 'grommet'
import { controllerStateStore } from 'store/ControllerStateStore'

export const SettingTable = observer (({
   setting,
   setModal
} : {
   setting : TCPSetting,
   setModal: React.Dispatch<React.SetStateAction<boolean>>
}) => {

   const { idProject } = useParams ()
   const [data, setData] = useState ([])

   const getSetting: TControlComand = useCallback (() => {

      const setCommand = async (
         project: number,
         alias  : string,
         command: number,
         value? : number | TTarget,
      ) => {

         const result = await setControllerCommand (
            project,
            alias,
            command, 
            value
         )
         return result
      }

      return setCommand (
         Number (idProject),
         setting.alias,
         setting.get
      )
   }, [idProject, setting])

   const setSetting = useCallback ((
      id   : string,
      value: string
   ) => {

      setData (prev => {

         const set = {
            id   : id,
            value: value
         }

         if (prev.some (set => set.id === id)) {
            const idx = prev?.flatMap (set => set.id).indexOf (id)
            prev?.splice (idx, 1, set)
            return prev
         } else return [...prev, set]
      })
   }, [setData])

   const { controlCommand } = useWaitCommand (getSetting)

   useEffect (() => {
      controllerStateStore.clearIntervals ()
      controlCommand (setting.alias)
      return () => controllerStateStore.getState (Number (idProject))
   }, [])

   if (zoneSettingStore.settings?.length == 0) return <Loader />
   else return (
      <Box
         direction = 'column'
         justify   = 'between'
         fill
      >
         <Table>
            <TableBody>

               { setting.settings.map (row => {
                  return <SettingRow
                     key        = { row.id }
                     setSetting = { setSetting }
                     row        = { row }
                  />
               }) }

            </TableBody>
         </Table>

         <Box
            direction = "row"
            justify   = 'between'
         >
            <Button
               type    = "reset"
               label   = "Отменить"
               onClick = { () => setModal (false) }
            />
            <Button
               primary
               label   = { 'Сохранить' }
               onClick = { () => {
                  setControllerSetting (Number (idProject), 'VentController', setting.set, data)
                  setModal (false)
               } }
            />
         </Box>
      </Box>
   )
})