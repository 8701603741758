import React from 'react'
import { observer } from 'mobx-react'

import { TControl             } from 'models/ControlPanel'
import { ControlFabric        } from "components/Library/ControlFabric/ControlFabric"
import { TControlComand       } from 'models/ControllerState'

import { useWaitCommand       } from 'components/Library/ControlFabric/hooks/useWaitCommand'
import { useCorrelation       } from 'hooks/useCorrelation'
import { controllerStateStore } from 'store/ControllerStateStore'

import { Select               } from 'grommet'

export const ControlSelect = observer (({
   alias,
   control,
   watchdog,
   sendCommand
} : {
   alias   : string,
   control : TControl,
   watchdog: boolean,
   sendCommand: TControlComand
}) => {

   const { controlCommand, wait } = useWaitCommand (sendCommand)

   const {
      unrender,
      style,
      className,
      label,
      disabled,
      ...correlation
   } = useCorrelation (
      control?.rel,
      control.options
      ?.find (option => option?.state == controllerStateStore?.state
      ?.find (state => state.name == control.id)?.value)?.func
   )

   if (!unrender) return (

      <ControlFabric wait = { wait }>
         <Select
            name     = 'radio'
            options  =  { control.options.map (option => ( {
               id      : option?.state,
               label   : label ?? option.label,
               value   : control.look
                         ? eval (control.look) (option?.state)
                         : option?.state,
            })) }
            labelKey = 'label'
            value    = { controllerStateStore.state
            ?.find (state => control.id == state.name)?.value }
            valueKey = 'id'
            onChange = { ({ value }) =>
               watchdog
               ? controlCommand (alias, control.options
               .find (option => option.state == value.value)?.command)
               : sendCommand    (alias, control.options
               .find (option => option.state == value.value)?.command) }
            size      = 'small'
            style     = { style }
            className = { className }
            disabled  = { disabled }
            { ...correlation }
         />
      </ControlFabric>
   )
})