import { useEffect, useState  } from "react"
import { correlationsStore    } from 'store/CorrelationsStore'
import { controllerStateStore } from "store/ControllerStateStore"

export type TCorrelation = {
   unrender?    : boolean,
   style?       : object,
   className?   : string,
   disabled?    : boolean,
   label?       : string,
   [key: string]: unknown
}

export const useCorrelation = (
   rel  : string,
   func?: string,
) : TCorrelation => {

   const [correlation, setCorrelation] = useState <TCorrelation> ({
      unrender : undefined,
      style    : undefined,
      className: undefined,
      disabled : undefined,
      label    : undefined,
   })

   useEffect (() => {

      if (func && !correlationsStore.correlations
      .find (correlation => correlation?.rel == rel)?.disabled)
         correlationsStore.setCorrelation (func)
   }, [controllerStateStore.state, func])

   useEffect (() => {

      if (rel) {
         const relation = correlationsStore.correlations
         .find (correlation => correlation?.rel == rel)

         if (relation) setCorrelation (prev => ({ ...prev, ...relation }))
      }
   }, [correlationsStore.correlations, rel])

   useEffect (() => {
      setCorrelation (prev => ({ ...prev, ...controllerStateStore.globalContext }))
   }, [controllerStateStore.globalContext])

   return correlation
}