import React from 'react'
import { observer             } from 'mobx-react'

import { controllerStateStore } from 'store/ControllerStateStore'
import { TControl             } from 'models/ControlPanel'
import { TControlComand       } from 'models/ControllerState'

import { useWaitCommand       } from 'components/Library/ControlFabric/hooks/useWaitCommand'
import { useCorrelation       } from 'hooks/useCorrelation'

import { ControlFabric        } from "components/Library/ControlFabric/ControlFabric"
import { Box, Button          } from 'grommet'

export const ControlButtonsGroup = observer (({
   alias,
   control,
   watchdog,
   sendCommand
}: {
   alias   : string,
   control : TControl,
   watchdog: boolean,
   sendCommand: TControlComand
}) => {

   const { controlCommand, wait } = useWaitCommand (sendCommand)

   const {
      unrender,
      style,
      className,
      label,
      disabled,
      ...correlation
   } = useCorrelation (
      control?.rel,
      control.options
      ?.find (option => option?.state == controllerStateStore?.state
      ?.find (state => state.name == control.id)?.value)?.func
   )

   if (!unrender) return (

      <ControlFabric wait = { wait } >
         <Box
            direction = 'row'
            flex      = 'grow'
            wrap
         >
            { control.options.map (option => (

               <Button
                  key       = { option?.state }
                  size      = "large"
                  primary   = { controllerStateStore?.state
                  ?.find (state => state.name == control.id)?.value == option?.state ? true : false }
                  label     = { label ?? controllerStateStore?.state
                  ?.find (state => state.name == control.id)?.value
                                   ? (option?.labelOn || option.label)
                                   : (option?.labelOff || option.label) } 
                  onClick   = { () => watchdog
                                      ? controlCommand (alias, option.command)
                                      : sendCommand    (alias, option.command) }
                  fill      = 'horizontal'
                  disabled  = { disabled }
                  style     = { {
                     ...style,
                     borderRadius : 0,
                     pointerEvents: option.command ? 'auto' : 'none'
                  } }
                  className = { className }
                  { ...correlation }
               />
            )) }
         </Box>
      </ControlFabric>
   )
})
