import React, { useEffect } from "react"
import { observer             } from "mobx-react"
import { useParams            } from "react-router-dom"

import { devicesStore         } from "store/DevicesStore"
import { settingsStore        } from "store/SettingsStore"
import { controllerStateStore } from "store/ControllerStateStore"
import { controlPanelStore    } from "store/ControlPanelStore"
import { sampleStore          } from "store/SampleStore"

import { Box, /* Tab, Tabs */ } from "grommet"
import { Loader               } from "components/Common/Loader"
import { Greeting             } from "components/Common/Greeting"
//import { Schedules            } from "components/Blocks/Schedules/Schedules"
//import { ControlBlocks        } from "components/Blocks/ControlBlocks/ControlBlocks"
import { TagBlocks            } from "components/Blocks/TagBlock/TagBlocks"
import { Groups               } from "components/Blocks/Groups/Groups"
import { Setting              } from "./Setting/Setting"

//import { riftStateStore } from "./Blocks/CustomBlocks/RiftSchedules/store/RiftsStore"

let isRetry = false

export const ControlPanel = observer (() => {

   const { idProject, idPanel } = useParams ()

   useEffect (() => {

      if (isRetry == false) {

         devicesStore.getDevices (Number (idProject))
         .then (() => {
            controlPanelStore.getState    (Number (idProject), Number (idPanel))
            controllerStateStore.getState (Number (idProject))
            settingsStore.getSettings     (Number (idProject))
            sampleStore.getState          (Number (idProject))
         })
      }

      isRetry = true
   }, [idProject])

   useEffect (() => {
      return () => {
         controllerStateStore.idProject = null
         sampleStore.idProject = null
      }
   }, [])

   //useEffect (() => {
   //   if (controllerStateStore.state.length > 0)
   //      riftStateStore.setRifts (controllerStateStore.state)
   //}, [controllerStateStore.state])

   //console.log (controllerStateStore.state)
   //console.log (toJS (controlPanelStore.projectConfig))

   if (devicesStore.loading         ||
       controlPanelStore.loading    ||
       controllerStateStore.loading ||
       settingsStore.loading) return <Loader />
   else return (
      <Box
         pad  = "large"
         fill = 'horizontal'
      >
         <Box
            direction = 'row'
            justify   = 'between'
            align     = 'center'
            wrap
         >
            <Box align = "bottom"><Greeting /></Box>

            { controlPanelStore.baseSetting?.settings?.length > 0 &&
               <Setting setting = { controlPanelStore?.baseSetting } />
            }
         </Box>

         { controllerStateStore.state?.length > 0
            ? (
               <Box>
                  { (controlPanelStore.tagBlocks?.length > 0 ||
                     controlPanelStore.indicationBlocks?.length > 0 ||
                     devicesStore.tags?.length > 0) &&
                     <TagBlocks /> }
                  <Groups />
                  { /* <ControlBlocks /> */ }
               </Box>
            ) : (
               <Box
                  align     = "center"
                  direction = 'column'
               >
                  <Box pad = { { bottom: 'large' } }>
                     Ожидание ответа контроллера...
                  </Box>
                  <Loader />
               </Box>
            )
         }

         { /* <Box width = '100%'>
                  <Tabs alignControls = 'start'>
                     <Tab title = "Сводка">
                        <Box pad = { { vertical: "medium" } }>
                        </Box>
                     </Tab>
                     <Tab title = "Управление">
                        <Box pad = { { vertical: "medium" } }>
                        </Box>
                     </Tab>
                     <Tab title = "Настройка">
                        <Box pad = "medium">Tree</Box>
                     </Tab>
                  </Tabs>
               </Box> */
         }

         { /* <Schedules /> */ }
      </Box>
   )
})