export const masks = {
   procent: {
      type: 'number',
      mask: [{
         length: [1, 3],
         regexp: /^[0-9]{1,2}$|^100$/,
         placeholder: '00'// + ` ${row?.sign}`,
      }]
   },
   resist: {
      type: 'number',
      mask: [{
         length: [1, 3],
         regexp: /^[0-9]{1,2}$|^1[0-9]{2}$|^200$/,
         placeholder: '00'// + ` ${row?.sign}`,
      }]
   },
   RangeGroup: {
      type: 'number',
      mask: [{
         length: [1, 3],
         regexp: /^[0-9]{1,2}$|^100$/,
         placeholder: '00'// + ` ${row?.sign}`,
      }]
   },
   Number: {
      type: 'number'
   }
}