import { useCallback, useState } from "react"
import { useParams             } from "react-router-dom"

import {
   TControlComand,
   TTarget
} from "models/ControllerState"
import { controllerStateStore } from "store/ControllerStateStore"
import { toast                } from "react-toastify"
import {
   CONTROL_TIMER,
   POLL_TIMER
} from "data/constants/globalConstants"
import { queryControllerState } from "api/controller"

export const useWaitCommand = (sendCommand: TControlComand) => {

   const { idProject }   = useParams ()
   const [wait, setWait] = useState (false)

   const controlCommand = useCallback (async (
      alias?  : string,
      command?: number,
      value?  : number | string | TTarget
   ) => {

      controllerStateStore.setGlobalContext ({ disabled: true })
      sendCommand (alias, command, value)
      .then (result => {

         let timer = true
         const getState = async () => {

            const state = await queryControllerState (Number (idProject), alias, result.Data.PacketId)

            if (state?.data?.find (stat => stat.name == 'RequestId')
            ?.value == result.Data.PacketId) {

               controllerStateStore.setState (state)
               controllerStateStore.setGlobalContext ({ disabled: false })
               toast.update (toastId, {
                  render   : "Состояние контроллера изменено",
                  type     : "success",
                  isLoading: false,
                  autoClose: 1000,
               })

               setWait (false)
            }
            else {

               if (timer === false) {

                  controllerStateStore.setGlobalContext ({ disabled: false })
                  toast.update (toastId, { 
                     render   : "Возможно, контроллер не изменил значение",
                     type     : "error",
                     isLoading: false,
                     autoClose: 1000,
                  })

                  setWait (false)
               }
               else setTimeout (() => getState (), POLL_TIMER)
            }
         }

         setWait (true)
         setTimeout (() => timer = false, CONTROL_TIMER)
         const toastId  = toast.info ('Ждём ответа контроллера', {
            autoClose: CONTROL_TIMER
         })
         getState ()
      })
   }, [])

   return { controlCommand, wait }
}