import React from 'react'

import { TSetting      } from 'models/ControlPanel'

import { TableRow      } from 'grommet'
import { RadioGroupRow } from './components/RadioGroupRow'
import { ToogleRow     } from './components/ToogleRow'
import { RangeInputRow } from './components/RangeInputRow'
import { RangeGroupRow } from './components/RangeGroupRow'
import { InputRow      } from './components/InputRow'
import { TextAreaRow   } from './components/TextAreaRow'
import { SelectRow     } from './components/SelectRow'

export const SettingRow = ({
   setSetting,
   row,
} : {
   setSetting: (i: string, v: string) => void,
   row       : TSetting
}) => {

   const cellControl = () => {

      switch (row.type) {
         case 'Select':
            return <SelectRow
               setSetting = { setSetting }
               row        = { row }
            />

         case 'TextArea':
         case 'JSON':
            return <TextAreaRow
               setSetting = { setSetting }
               row        = { row }
            />

         case 'RangeInput':
            return <RangeInputRow
               setSetting = { setSetting }
               row        = { row }
            />

         case 'Toogle':
            return <ToogleRow
               setSetting = { setSetting }
               row        = { row }
            />

         case 'RadioButtonGroup':
            return <RadioGroupRow
               setSetting = { setSetting }
               row        = { row }
            />

         case 'RangeGroup':
            return <RangeGroupRow
               setSetting = { setSetting }
               row        = { row }
            />

         default:
            return <InputRow
               setSetting = { setSetting }
               row        = { row }
            />
      }
   }

   return (
      <TableRow
         style     = { row.style }
         className = { row.class }
      >
         { cellControl () }
      </TableRow>
   )
}